<template>
	<div class="my-nav-left">
		<div class="nav-left-first">
			<div v-for="(item,index) in navList">
				<div class="nav-item" :class="{active:routePath==item.url||navFirstActive==index}" v-if="index!==2" @click="navFirstClick(item,index)">
					<i class="icon" :class="item.icon"></i>
					<span class="title">{{item.title}}</span>
				</div>
				<div class="nav-item" :class="{active:routePath==item.url}" v-if="index==2&&warehouse"
					@click="navFirstClick(item,index)">
					<i class="icon" :class="item.icon"></i>
					<span class="title">{{item.title}}</span>
				</div>
			</div>
		</div>
		<div class="nav-left-second" v-if="navSecondList">
			<div v-for="(item,index) in navSecondList">
				<div class="nav-item" :class="{active:routePath==item.url}" @click="navSecondClick(item,index)">
					<i class="icon" :class="item.icon"></i>
					<span class="title">{{item.title}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				navFirstActive:'0',
				routePath: '',
				navSecondList:null,
				navList: [{
					title: '个人中心',
					icon: 'el-icon-s-home',
					url: '/myhome'
				}, {
					title: '订单中心',
					icon: 'el-icon-document',
					children: [{
						title: '快速下单',
						url: '/myhome/quick'
					}, {
						title: '我的订单',
						url: '/myhome/myorder'
					},{
						title: '退换/售后',
						url: '/myhome/returnlist'
					}]
				}, {
					title: '物流上传',
					icon: 'el-icon-upload2',
					url: '/myhome/express'
        }, {
          title: '库存管理',
          icon: 'el-icon-upload2',
          url: '/myhome/inventory'
				}, {
					title: '我的资产',
					icon: 'el-icon-coin',
					children: [{
						title: '我的钱包',
						url: '/myhome/wallet'
					}, {
						title: '我的积分',
						url: '/myhome/integral'
					},
					// {
					// 	title: '我的优惠券',
					// 	url: '/myhome/mycoupon'
					// },
					]
				}, {
					title: '我的服务',
					icon: 'el-icon-headset',
					children: [
					// 	{
					// 	title: '领券中心',
					// 	url: '/myhome/coupon'
					// },
					 {
						title: '收货地址',
						url: '/myhome/myaddress'
					},
					// {
					// 	title: '我的银行卡',
					// 	url: '/myhome/bankcard'
					// },
					{
						title: '我的帮助',
						url: '/myhome/help'
					}]
				},
				{
					title: '快智配',
					icon: 'el-icon-truck',
					url: '/myhome/kuaizhipei'
				}
                //     {
				// 	title: '进销存',
				// 	icon: 'el-icon-finished',
				// 	children: [{
				// 		title: '商品分类',
				// 		url: '/myhome/psi-category'
				// 	}, {
				// 		title: '商品管理',
				// 		url: '/myhome/psi-goods'
				// 	}, {
				// 		title: '供应商管理',
				// 		url: '/myhome/psi-customer?type=1'
				// 	}, {
				// 		title: '客户管理',
				// 		url: '/myhome/psi-customer?type=2'
				// 	}, {
				// 		title: '进货单管理',
				// 		url: '/myhome/psi-order?type=1'
				// 	}, {
				// 		title: '销售单管理',
				// 		url: '/myhome/psi-order?type=2'
				// 	}, {
				// 		title: '商品库存',
				// 		url: '/myhome/psi-inventory'
				// 	}]
				// }
                ],
				warehouse: '',
			};
		},
		watch: {
			$route() {

				this.initIndex()
			},
		},
		created() {
			this.warehouse = localStorage.getItem('warehouse')
      if (this.warehouse === undefined) {
        this.warehouse = ''
      }
			this.initIndex()
		},
		methods: {
			initIndex(){
				if(JSON.stringify(this.$route.query) !== '{}'){
					this.routePath = this.$route.path+'?type='+this.$route.query.type
				}else{
					this.routePath = this.$route.path
				}
				let arr=[]
				for (var i = 0; i < this.navList.length; i++) {
				  if(this.navList[i].url==this.routePath){
						this.navFirstActive=i
					}
					if(this.navList[i].children){
						let arr=this.navList[i].children
						for (var j = 0; j < arr.length; j++) {
						  if(arr[j].url==this.routePath){
						  	this.navFirstActive=i
						  }
						}
					}
				}
				this.navSecondList=this.navList[this.navFirstActive].children
			},
			navFirstClick(item,index) {
				this.navFirstActive=index
				if(item.url){
					this.$router.push(item.url)
					this.navSecondList=null
				}else{
					this.navSecondList=item.children
					this.$router.push(item.children[0].url)
				}
			},
			navSecondClick(item,index){
				this.$router.push(item.url)
			},
			inventory() {
				this.$message({
					message: '正在维护中...',
					type: 'success'
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	// 我的主页左侧导航
</style>
